import { InfoCircledIcon } from "@radix-ui/react-icons";
import { Callout } from "sparta";

export interface IErrorAtomState {
  success: string;
  errorMessage: string;
}

interface IErrorMessageAtom {
  message: string;
  type?: "error" | "info" | "warning";
}

export default function UIMessageAtom({ message, type }: IErrorMessageAtom) {
  return (
    <Callout.Root color={type === "error" ? "red" : type === "info" ? "blue" : "yellow"}>
      <Callout.Icon>
        <InfoCircledIcon />
      </Callout.Icon>
      <Callout.Text>{message}</Callout.Text>
    </Callout.Root>
  );
}
